<template>
  <v-card>
    <v-card-title>
      <h3 class="text-h6">{{ seriesTitle }}</h3>
    </v-card-title>
    <v-card-text>
      <vue-apex-charts :key="k" :options="chartOptions" :series="series" />
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import "apexcharts/dist/locales/fr.json";

export default {
  name: "RepublishingChart",
  components: {
    VueApexCharts,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    seriesTitle: {
      type: String,
      required: true,
    },
    seriesData: {
      type: Array,
      required: true,
    },
    repubDates: {
      type: Array,
      required: true,
    },
    dates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      k: 0,
      series: [],
      chartOptions: {},
    };
  },
  methods: {
    updateChart() {
      this.series = [
        {
          name: this.seriesTitle,
          data: this.seriesData,
        },
      ];
      const annotations = {
        xaxis: [],
      };
      for (let d of this.repubDates) {
        annotations.xaxis.push({
          x: new Date(d).getTime(),
          borderColor: "#000",
          fillColor: "#FEB019",
          opacity: 0.2,
          label: {
            borderColor: "#333",
            style: {
              fontSize: "10px",
              color: "#333",
              background: "#FEB019",
              minWidth: "200px",
              minHeight: "80px",
            },
            text: d,
          },
        });
      }
      this.chartOptions = {
        chart: {
          height: 350,
          // type: "area",
          type: "bar",
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: true,
          },
        },
        // fill: {
        //   type: "gradient",
        //   gradient: {
        //     shadeIntensity: 1,
        //     opacityFrom: 0.7,
        //     opacityTo: 0.9,
        //     stops: [0, 90, 100]
        //   }
        // },
        annotations: annotations,
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          padding: {
            right: 30,
            left: 20,
          },
        },
        labels: this.dates,
        xaxis: {
          type: "datetime",
        },
        // yaxis: {
        //   logarithmic: true,
        //   logBase: 10,
        // },
      };
      setTimeout(function () {
        window.dispatchEvent(new Event("resize"));
      }, 500);
    },
  },
  watch: {
    sessionSeries() {
      this.updateChart();
      this.k += 1;
    },
    dates() {
      this.updateChart();
      this.k += 1;
    },
  },
};
</script>

<style scoped></style>
