<template>
  <div class="percentage-bar">
    <v-tooltip top>
      <template #activator="{ on }">
        <div class="left-bar" :style="leftBarStyle" v-on="on">
          {{ leftPercentage > 17 ? leftText : "" }}
        </div>
      </template>
      <span>{{ leftTextLong }}</span>
    </v-tooltip>
    <v-tooltip top>
      <template #activator="{ on }">
        <div class="right-bar" :style="rightBarStyle" v-on="on">
          {{ rightPercentage > 17 ? rightText : "" }}
        </div>
      </template>
      <span>{{ rightTextLong }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { formatNumberCompact, formatNumber } from "@/utils/formatting";
export default {
  props: {
    value1: {
      type: Number,
      required: true,
    },
    value2: {
      type: Number,
      required: true,
    },
    color1: {
      type: String,
      default: "#ffc300",
    },
    color2: {
      type: String,
      default: "#ff9500",
    },
  },
  methods: {
    formatNumberCompact,
    formatNumber,
  },
  computed: {
    total() {
      return this.value1 + this.value2;
    },
    leftPercentage() {
      return Math.round((this.value1 / this.total) * 100);
    },
    rightPercentage() {
      return Math.round((this.value2 / this.total) * 100);
    },
    leftText() {
        return `${this.formatNumberCompact(this.value1)} (${this.leftPercentage}%)`;
    },
    leftTextLong() {
      return `${this.formatNumber(this.value1)} (${this.leftPercentage}%)`;
    },
    rightText() {
        return `${this.formatNumberCompact(this.value2)} (${this.rightPercentage}%)`;
    },
    rightTextLong() {
        return `${this.formatNumber(this.value2)} (${this.rightPercentage}%)`;
    },
    leftBarStyle() {
      return {
        width: this.leftPercentage + "%",
        backgroundColor: this.color1,
      };
    },
    rightBarStyle() {
      return {
        width: this.rightPercentage + "%",
        backgroundColor: this.color2,
      };
    },
  },
};
</script>

<style scoped>
.percentage-bar {
  display: flex;
  width: 100%;
  min-width: 200px;
  height: 26px; /* Adjust this height to fit within your table row */
  margin: 0 0 0 0;
  border-radius: 5px;
}

.left-bar,
.right-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;
  font-size: 0.6rem;
  font-family: Inter, sans-serif;
  font-weight: 600;
}
</style>
