<template>
  <v-autocomplete
    outlined
    v-model="categories"
    :items="categoryList"
    :menu-props="{ maxHeight: '400', zIndex: 13 }"
    label="Sélectionner les rubriques"
    multiple
    small-chips
    deletable-chips
  ></v-autocomplete>
</template>

<script>
/**
 * Either call this component giving an `initialValue` (int[])
 *  and listening on `valueChanged` event,
 * Or call it specifying a `store`
 *  (and eventually `storeGetter`/`storeUpdater`)
 */
export default {
  name: "CategoriesFilter",
  props: {
    initialValue: {
      type: Array,
      validator(values) {
        return (
          values.length === 0 ||
          values.every((value) => typeof value === "string")
        );
      },
    },
    store: {
      type: String,
      validator(value) {
        /* List of stores having :
        - getters["<store>/<storeGetter>"]
        - dispatch("<store>/<storeUpdater>", payload)
        */
        return ["audience", "gd"].includes(value);
      },
    },
    storeGetter: {
      type: String,
      default: "getCategories",
    },
    storeUpdater: {
      type: String,
      default: "updateCategories",
    },
  },
  data() {
    return {};
  },
  async created() {
    await this.refreshCategoryList();
  },
  methods: {
    async refreshCategoryList() {
      await this.$store.dispatch("common/getCategoryList", {
        site_ids: this.sites.join(","),
      });
    },
  },
  computed: {
    categories: {
      get() {
        return this.$store.getters[`${this.store}/${this.storeGetter}`];
      },
      set(e) {
        this.$store.dispatch(`${this.store}/${this.storeUpdater}`, e);
      },
    },
    sites() {
      return this.$store.getters[`${this.store}/getSites`];
    },
    categoryList() {
      return this.$store.getters["common/getCategoryList"];
    },
  },
  watch: {
    async sites() {
      await this.refreshCategoryList();
    },
  },
};
</script>

<style scoped>
[role="menu"] {
  top: 233px !important;
  right: 0 !important;
}
</style>
