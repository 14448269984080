<template>
  <v-card class="pa-4">
    <div class="d-flex justify-center">
      <v-spacer></v-spacer>
      <v-btn icon @click.native="$emit('openDialog', false)">
        <v-icon color="error">{{ icons.close }}</v-icon>
      </v-btn>
    </div>
    <v-card-title class="text-h5">
      <a target="_blank" :href="url">{{ truncate(title, 110) }}</a>
    </v-card-title>
    <v-card-text class="mt-6">
      <v-row>
        <v-col cols="12" md="12" lg="6">
          <republishing-chart
            :id="id"
            seriesTitle="Sessions"
            :seriesData="sessionsSeries"
            :repubDates="repubDates"
            :dates="dates"
          />
        </v-col>
        <v-col cols="12" md="12" lg="6">
          <republishing-chart
            :id="id"
            seriesTitle="CA"
            :seriesData="caSeries"
            :repubDates="repubDates"
            :dates="dates"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "@axios";
import RepublishingChart from "@/components/google-discover/republishing-discover-stats/charts/RepublishingChart";
import { mdiCloseThick as close } from "@mdi/js";
import { truncate } from "@/utils/formatting";

export default {
  name: "RepublishingPostInfosModal",
  created() {
    this.getPostData();
  },
  components: {
    RepublishingChart,
  },
  props: {
    dialogOpen: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    truncate,
    async getPostData() {
      const { data } = await axios.get(
        `/google-discover/republishing-discover-stats/${this.id}`
      );
      this.caSeries = data.ca;
      this.sessionsSeries = data.sessions;
      this.dates = data.dates;
      this.repubDates = data.publicationDates;
    },
    dialoagOpen() {
      this.$emit("openDialog");
    },
  },
  data() {
    return {
      icons: {
        close,
      },
      caSeries: [],
      sessionsSeries: [],
      repubDates: [],
      dates: [],
    };
  },
  watch: {
    id() {
      this.getPostData();
    },
  },
};
</script>
<style scoped>
a {
  color: #3f51b5;
  text-decoration: none;
}
a:hover {
  color: #1e88e5;
}
</style>
