<template>
  <v-card>
    <v-card-title class="align-start">
      <v-row class="mt-4">
        <v-col cols="4">
          <sites-filter store="gd"></sites-filter>
        </v-col>
        <v-col cols="4">
          <date-filter
            store="gd"
            :range="7"
            periodType="past-only"
            :max="maxDate"
          ></date-filter>
        </v-col>
        <v-col cols="4">
          <categories-filter store="gd"></categories-filter>
        </v-col>
        <v-spacer />
        <v-col cols="2" class="d-flex">
          <apply-filters
            title="Appliquer"
            :disabled="disabled"
            styles="height: 56px !important; width: 100% !important;"
            classList=""
          />
        </v-col>
      </v-row>
      <dot-menu
        @exportExcel="exportExcel"
        :excelButton="{
          excelData,
          filename: `republishing-stats-export.xlsx`,
        }"
      />
      <v-progress-linear
        v-if="isLoading"
        :indeterminate="true"
        color="#10396f"
        background-color="#ebebf3"
        class="mt-2"
      ></v-progress-linear>
    </v-card-title>
  </v-card>
</template>

<script>
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import DateFilter from "@/components/common/filters/DateFilter.vue";
import SitesFilter from "@/components/common/filters/SitesFilter.vue";
import CategoriesFilter from "@/components/common/filters/CategoriesFilter.vue";
import useAxios from "@/hooks/useAxios";
import debounce from "lodash/debounce";
import DotMenu from "@/components/common/menus/DotMenu";
import axios from "@axios";

export default {
  name: "Filters",
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ApplyFilters,
    DateFilter,
    SitesFilter,
    CategoriesFilter,
    DotMenu,
  },
  data() {
    return {
      disabled: true,
      value: 25,
      panel: 0,
      excelData: new Blob([""], { type: "text/plain" }),
    };
  },
  setup() {
    const { isLoading } = useAxios();
    const { axiosGet } = useAxios();

    return {
      isLoading,
      axiosGet,
    };
  },

  created() {
    this.updateDisabled();
  },
  methods: {
    debouncedUpdateDisabled: debounce(async function () {
      this.updateDisabled();
    }, 500),
    updateDisabled() {
      // this.disabled = isEmpty(this.sites) || this.isLoading;
      this.disabled = this.isLoading;
    },
    async exportExcel() {
      const { data } = await axios.get("/google-discover/republishing-discover-stats/excel", {
        params: {
          sites: this.sites.join(","),
          categories: this.categories.join("=="),
          dates: this.dates.join(","),
        },
        responseType: "blob",
      });
      this.excelData = data;
    },
  },
  computed: {
    loading() {
      return this.isLoading;
    },
    sites() {
      return this.$store.getters["gd/getSites"];
    },
    categories() {
      return this.$store.getters["gd/getCategories"];
    },
    dates() {
      return this.$store.getters["gd/getDates"];
    },
    maxDate() {
      // always set the max date to 2 days ago
      // because we don't have recent google discover data
      const today = new Date();
      today.setDate(today.getDate() - 2);
      return today.toISOString().split("T")[0];
    },
  },
  watch: {
    isLoading() {
      this.debouncedUpdateDisabled();
    },
    async sites() {
      this.debouncedUpdateDisabled();
    },
    categories() {
      this.debouncedUpdateDisabled();
    },
  },
};
</script>

<style scoped></style>
