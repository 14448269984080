import { render, staticRenderFns } from "./RepublishingDiscoverStats.vue?vue&type=template&id=445f2db6&scoped=true&"
import script from "./RepublishingDiscoverStats.vue?vue&type=script&lang=js&"
export * from "./RepublishingDiscoverStats.vue?vue&type=script&lang=js&"
import style0 from "./RepublishingDiscoverStats.vue?vue&type=style&index=0&lang=css&"
import style1 from "./RepublishingDiscoverStats.vue?vue&type=style&index=1&id=445f2db6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "445f2db6",
  null
  
)

export default component.exports