<template>
  <div class="gdi-container">
    <p class="text-2xl mb-6">Republishing Discover Stats</p>
    <filters/>
    <global-stats-card/>
    <republishing-discover-stats-table/>
  </div>
</template>
<script>
import Filters from "@/components/google-discover/republishing-discover-stats/filters/Filters.vue";
import RepublishingDiscoverStatsTable from "@/components/google-discover/republishing-discover-stats/tables/RepublishingDiscoverStatsTable.vue";
import GlobalStatsCard from "@/components/google-discover/republishing-discover-stats/cards/GlobalStatsCard.vue";
import debounce from "lodash/debounce";
import useAxios from "@/hooks/useAxios";
import { runParallelAsyncs } from "@/utils/async";
import axios, { cacheTime } from "@axios";

export default {
  name: "RepublishingDiscoverStats",
  components: {
    Filters,
    RepublishingDiscoverStatsTable,
    GlobalStatsCard,
  },
};
</script>
<style>
.app-content-container.mx-auto.h-full.app-content-container-boxed {
  max-width: 100%;
}
</style>
<style lang="scss" scoped>
.theme--light.v-tabs-items {
  background-color: #f4f5fa;
}
/* remove links underline */
.gdi-container ::v-deep a {
  text-decoration: none !important;
}
.gdi-container ::v-deep tbody tr:nth-of-type(even) {
  background-color: rgba(0, 1, 100, 0.08);
}
.gdi-container ::v-deep tbody tr:hover {
  background-color: #10396f !important;
  td {
    color: white !important;
    a {
      color: white !important;
    }
  }
}
</style>
